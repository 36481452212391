import { createBrowserRouter } from "react-router-dom";
import Home from '../pages/home';
import Layout from '../pages/layout';
import Mining from '../pages/mining';
import Ecology from '../pages/ecology';
import Bridge from '../pages/bridge';
import Nft from '../pages/nft';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "mining",
                element: <Mining />
            },
            {
                path: "ecology",
                element: <Ecology />
            },
            {
                path: "bridge",
                element: <Bridge />
            },
            {
                path: "base",
                element: <Nft />
            }
        ]
    }
]);

export default router;