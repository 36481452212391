import { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

const Wrap = styled.div``;
const Header = styled.div.attrs({
    className: 'h-[56px] md:h-[72px] px-[12px] md:px-[64px] flex items-center sticky top-0 z-10'
})`
    /* transition: all 150ms ease; */
    backdrop-filter: ${p => p.$flur && 'blur(12px)'};
`;
const MenuWrap = styled.div.attrs({
    className: 'flex-1 items-center hidden md:flex'
})``;
const MenuItem = styled.div.attrs({
    className: 'text-[18px] mr-[50px] cursor-pointer'
})`
    color: ${p => p.$active ? '#FFFFFF' : 'rgba(255,255,255,0.6)'};
    transition: color 250ms ease;
    &:hover {
        color: #FFFFFF;
    }
`;

const MobileMenuIcon = styled(FormatListBulletedIcon).attrs({
    className: 'ml-auto block md:!hidden mr-[6px]'
})``;

const MobileMenuDrawer = styled(Drawer).attrs({
    className: ''
})`
    & .MuiPaper-root {
        width: 100%;
        background-color: rgb(48, 50, 53);
        color: #FFFFFF;
        padding: 12px 16px;
    }
`

const MobileMenuList = styled.div.attrs({
    className: ''
})``

const MobileMenuItem = styled.div.attrs({
    className: 'p-[16px] text-[18px]'
})``

const MobileCloseIcon = styled(CloseIcon).attrs({
    className: 'absolute top-[26px] right-[16px]'
})``

const Logo = styled.img.attrs({
    className: 'w-[42px] max-md:w-[32px] mr-[64px]'
})``

export default function Layout() {
    const location = useLocation();
    const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [isPageTop, setIsPageTop] = useState(true);

    useEffect(() => {
        const scrollHandle = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if(scrollTop <= 0) {
                setIsPageTop(true);
            } else {
                setIsPageTop(false);
            }
        }
        window.addEventListener('scroll', scrollHandle);

        return () => {
            window.removeEventListener('scroll', scrollHandle);
        }
    }, [])

    function onRouter(path) {
        navigate(path);
        setShowMobileMenu(false);
    }

    function onMobileMenu() {
        setShowMobileMenu(true);
    }

    return (
        <Wrap>
            <Header $flur={!isPageTop}>
                <Logo src='/imgs/logo.png'></Logo>
                <MenuWrap>
                    <MenuItem onClick={() => onRouter('/')} $active={location.pathname === '/'}>Home</MenuItem>
                    <MenuItem onClick={() => onRouter('/ecology')} $active={location.pathname === '/ecology'}>Ecology</MenuItem>
                    <MenuItem onClick={() => onRouter('/bridge')} $active={location.pathname === '/bridge'}>Bridge</MenuItem>
                    <MenuItem onClick={() => onRouter('/base')} $active={location.pathname === '/base'}>Base</MenuItem>
                    <MenuItem $active={location.pathname === '/mining'}>Mining(Soon)</MenuItem>
                </MenuWrap>
                <MobileMenuIcon onClick={onMobileMenu}></MobileMenuIcon>
                {/* <ConnectButton label="Connect" chainStatus="none"></ConnectButton> */}
            </Header>
            <Outlet></Outlet>

            <MobileMenuDrawer
                anchor='right'
                open={showMobileMenu}
                onClose={() => { setShowMobileMenu(false) }}
            >
                <MobileMenuList>
                    <MobileMenuItem onClick={() => onRouter('/')}>Home</MobileMenuItem>
                    <MobileMenuItem onClick={() => onRouter('/ecology')}>Ecology</MobileMenuItem>
                    <MobileMenuItem onClick={() => onRouter('/bridge')}>Bridge</MobileMenuItem>
                    <MobileMenuItem onClick={() => onRouter('/base')}>Base</MobileMenuItem>
                    <MobileMenuItem>Mining(Soon)</MobileMenuItem>
                </MobileMenuList>

                <MobileCloseIcon onClick={() => setShowMobileMenu(false)}></MobileCloseIcon>
            </MobileMenuDrawer>
        </Wrap>
    )
}