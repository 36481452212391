import {useState} from 'react';
import styled from 'styled-components';
import ExchangeFacet from '../../components/exchange-facet';
import ExchangeBtc from '../../components/exchange-btc';

const Wrap = styled.div.attrs({
    className: 'pt-[24px] px-[12px]'
})``;

const TabWrap = styled.div.attrs({
    className: 'flex justify-center'
})`
    
`;

const TabItem = styled.div.attrs({
    className: 'px-[12px] text-[18px] cursor-pointer h-[44px] leading-[44px]'
})`
    border-bottom: 2px solid ${p => p.$active ? '#FFFFFF' : 'transparent'};
    color: ${p => p.$active ? '#FFFFFF' : 'rgba(255,255,255,0.6)'};
    transition: color 250ms ease;
    &:hover {
        color: ${p => !p.$active && 'rgba(255,255,255,0.8)'};
    }
`;

export default function Bridge() {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <Wrap>
            <TabWrap>
                <TabItem onClick={() => setActiveTab(0)} $active={activeTab === 0}>Facet</TabItem>
                <TabItem onClick={() => setActiveTab(1)} $active={activeTab === 1}>Bitcoin</TabItem>
            </TabWrap>

            {
                activeTab === 0 ? (
                    <ExchangeFacet></ExchangeFacet>
                ) : (
                    <ExchangeBtc></ExchangeBtc>
                )
            }
            
        </Wrap>
    )
}