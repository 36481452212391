import { useEffect, useCallback, useMemo, useState } from 'react';
import { useReadContracts, useAccount, useWriteContract, useBalance } from 'wagmi';
import styled from 'styled-components';
import { NFT_ADDR, NFT_ABI } from '../../constract';
import { formatEther, parseEther } from 'viem';
import CustomConnectButton from '../../components/custom-connect-button';
import ErrorIcon from '@mui/icons-material/Error';
import Dialog from '@mui/material/Dialog';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { config } from '../../components/web3-provider';
import CircularProgress from '@mui/material/CircularProgress';

const Wrap = styled.div.attrs({
    className: 'px-[64px] max-md:px-[12px] pt-[24px] pb-[50px]'
})``;
const Box = styled.div.attrs({
    className: 'max-w-[1000px] max-md:max-w-full mx-auto flex max-md:block'
})``;

const NftImg = styled.img.attrs({
    className: 'w-full h-full'
})`
    transition: transform 250ms ease;
`;

const MintBox = styled.div.attrs({
    className: 'w-[470px] max-md:w-full mr-[64px] max-md:mr-0 p-[24px] max-md:p-[12px]'
})`
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 24px;
    transition: border 250ms ease;
    &:hover {
        border: 1px solid rgba(253,126,20, 0.6);
        & ${NftImg} {
            transform: rotate(3deg) scale(1.1);
        }
    }
`;
const NftBox = styled.div.attrs({
    className: 'w-[420px] max-md:w-full h-[420px] overflow-hidden rounded-[12px]'
})``;

const NftName = styled.div.attrs({
    className: 'mt-[24px] text-[20px]'
})``;
const ProgressOuter = styled.div.attrs({
    className: 'mt-[12px] h-[24px] rounded-[12px] overflow-hidden'
})`
    background-color: rgba(255,255,255,0.06);
`;
const ProgressInner = styled.div.attrs({
    className: 'h-full bg-[#FD7E14]'
})`
    width: ${p => p.$width ? p.$width : '0'}%;
`;
const SubmitBar = styled.div.attrs({
    className: 'mt-[48px] flex items-center'
})``;
const PriceBox = styled.div.attrs({ className: '' })``;
const PriceLabel = styled.div.attrs({
    className: 'text-[14px] font-semibold'
})``;
const PriceVal = styled.div.attrs({
    className: 'text-[20px] font-semibold'
})``;
const MintButton = styled.div.attrs({
    className: 'ml-auto h-[52px] rounded-[8px] inline-flex items-center px-[64px] cursor-pointer'
})`
    background: linear-gradient(225deg, #0080ff, #7d41ea 46.35%, #FD7E14);
    opacity: ${p => p.$disabled ? 0.6 : 1};
    cursor: ${p => p.$disabled ? 'not-allowed' : 'pointer'};
    transition: transform 250ms ease;
    &:hover {
        transform: scale(1.05);
    }
`;
const RightWrap = styled.div.attrs({
    className: 'flex-1 max-md:mt-[20px]'
})``;
const RightTitle = styled.div.attrs({
    className: 'text-[32px] max-md:text-[24px] text-[#FD7E14] font-semibold'
})``;
const RightDesc = styled.div.attrs({
    className: 'mt-[12px]'
})`
    color: rgba(255,255,255,0.8);
    
`;

const InfoBox = styled.div.attrs({
    className: 'text-[20px] mt-[24px] pt-[12px]'
})`
    border-top: 1px solid rgba(255,255,255,0.12);
`;

const InfoRow = styled.div.attrs({
    className: 'text-[18px] mt-[24px]'
})``;

const TimeCountDown = styled.div.attrs({
    className: 'flex items-center'
})``;

const CountDownItem = styled.div.attrs({
    className: 'mr-[12px] w-[64px] text-center py-[12px] rounded-[6px]'
})`
    background-color: rgba(255,255,255,0.08);
`;

const WalletStatus = styled.div.attrs({
    className: 'max-w-[1000px] max-md:max-w-full mx-auto flex items-center mb-[12px]'
})``;

const ErrorTip = styled.div.attrs({
    className: 'mt-[12px] flex items-center',
})`
    color: rgb(211, 47, 47);
`;

const TipDialog = styled(Dialog).attrs({
    className: ''
})`
    & .MuiPaper-root {
        background-color: rgb(49, 52, 57);
        border-radius: 24px;
    }
`;

const TipOuter = styled.div.attrs({
    className: 'text-white w-[460px] text-center p-[24px]'
})`
    
`;

const TipLint = styled.div.attrs({
    className: 'mt-[24px] cursor-pointer'
})`
    
`;

const TipSuccessIcon = styled(CheckCircleOutlineIcon).attrs({
    className: 'text-[#84cc16] !text-[100px]'
})`
`;

const UserNftButton = styled.div.attrs({
    className: 'cursor-pointer ml-auto'
})`
`;

const LoadIcon = styled(CircularProgress).attrs({
    size: 20,
})`
    color: #FFFFFF;
    margin-right: 8px;
`;

const wagmiContractConfig = {
    abi: NFT_ABI,
    address: NFT_ADDR,
}

export default function Nft() {
    const { address, isDisconnected, chainId } = useAccount();
    const { data: hash, writeContract, error, status, isError } = useWriteContract();
    const [cutDownData, setCutDownData] = useState({ d: 0, h: 0, m: 0, s: 0, t: 0 });
    const { data, isFetched } = useReadContracts({
        contracts: [
            {
                ...wagmiContractConfig,
                functionName: 'balanceOf',
                args: [address],
            },
            {
                ...wagmiContractConfig,
                functionName: 'getClaimFee'
            },
            {
                ...wagmiContractConfig,
                functionName: 'maxBoxs',
                // functionName: 'maxNFT'
            },
            {
                ...wagmiContractConfig,
                functionName: 'totalSupply'
            },
        ],
    })

    const { data: balance, isFetched: balanceIsFetched } = useBalance({
        address: address,
    })

    const [openTipDialog, setOpenTipDialog] = useState(false);
    const [tipDialogText, setTipDialogText] = useState('');

    const [errorText, setErrorText] = useState('');
    const [mintLoad, setMintLoad] = useState('');
    const nftData = useMemo(() => {
        if (isFetched && data) {
            return {
                balance: String(data[0].result || 0),
                claimFee: formatEther(data[1].result),
                maxBoxs: String(data[2].result),
                totalSupply: String(data[3].result)
            }
        }

        return {
            balance: 0,
            claimFee: 0,
            maxBoxs: 0,
            totalSupply: 0
        }
    }, [isFetched, data]);
    console.log(nftData, 'nftData.claimFee');
    useEffect(() => {
        calcCountDown();
    }, []);

    function calcCountDown() {
        const endTime = new Date('2024/4/17 21:00:00').getTime();
        const startTime = Date.now();
        const diffTime = endTime - startTime;
        const restDay = Math.floor(diffTime / 1000 / 60 / 60 / 24);
        const restHour = Math.floor((diffTime / 1000 / 60 / 60) % 24);
        const restMinute = Math.floor((diffTime / 1000 / 60) % 60);
        const restSecond = Math.floor((diffTime / 1000) % 60);
        if (diffTime > 0) {
            setCutDownData({ d: restDay, h: restHour, m: restMinute, s: restSecond, t: diffTime });
            setTimeout(() => {
                calcCountDown();
            }, 200);
        } else {
            setCutDownData({ d: 0, h: 0, m: 0, s: 0, t: 0 });
        }
    }

    async function onMint() {
        return;
        setErrorText('');
        if (isDisconnected || chainId !== config?.chains?.[0]?.id || !balanceIsFetched || cutDownData.t > 0 || mintLoad || Number(nftData.totalSupply) >= Number(nftData.maxBoxs)) return;
        // if(isDisconnected || chainId !== config?.chains?.[0]?.id || !balanceIsFetched || mintLoad || nftData.totalSupply >= nftData.maxBoxs) return;
        console.log(formatEther(balance?.value || 0), nftData.claimFee, 0);
        if (formatEther(balance?.value || 0) < nftData.claimFee) {
            setErrorText('Insufficient balance');
            return;
        }

        setMintLoad(true);

        writeContract({
            ...wagmiContractConfig,
            functionName: 'claim',
            value: parseEther(nftData.claimFee),
        }, {
            onSuccess(data) {
                setTipDialogText(data);
                setOpenTipDialog(true);
            },
            onError(err) {
                setErrorText(err.details);
            },
            onSettled() {
                setMintLoad(false);
            }
        })
    }

    return (
        <Wrap>
            <WalletStatus>
                <CustomConnectButton></CustomConnectButton>
                <UserNftButton onClick={() => window.location.href = 'https://opensea.io/'}>My BaseMagicBox: {nftData.balance} →</UserNftButton>
            </WalletStatus>
            <Box>
                <MintBox>
                    <NftBox>
                        <NftImg src='/imgs/tokens/runestone.gif'></NftImg>
                    </NftBox>
                    <NftName>BaseMagicBox</NftName>
                    <ProgressOuter>
                        <ProgressInner $width={nftData.totalSupply / nftData.maxBoxs * 100}></ProgressInner>
                    </ProgressOuter>
                    <SubmitBar>
                        <PriceBox>
                            <PriceLabel>Mint Price:</PriceLabel>
                            <PriceVal>{nftData.claimFee} ETH</PriceVal>
                        </PriceBox>
                        <MintButton $disabled={true} onClick={onMint}>
                            Closed
                            {/* {
                                mintLoad && <LoadIcon></LoadIcon>
                            } */}
                            {/* {
                                nftData.totalSupply >= nftData.maxBoxs ? 'Closed' : 'Mint'
                            } */}
                        </MintButton>
                        {/* <MintButton $disabled={isDisconnected || (chainId !== config?.chains?.[0]?.id) || (nftData.maxBoxs > 0 && nftData.totalSupply >= nftData.maxBoxs) || mintLoad} onClick={onMint}>
                            {
                                mintLoad && <LoadIcon></LoadIcon>
                            }
                            {
                                (nftData.maxBoxs > 0 && nftData.totalSupply >= nftData.maxBoxs) ? 'Closed' : 'Mint'
                            }
                        </MintButton> */}
                    </SubmitBar>
                    {
                        errorText && <ErrorTip><ErrorIcon className='mr-[4px]'></ErrorIcon> {errorText}</ErrorTip>
                    }
                </MintBox>
                <RightWrap>
                    <RightTitle>MagicBox</RightTitle>
                    <RightDesc>The MagicBox series has a total supply of 10,000, divided into 2 parts.</RightDesc>
                    <RightDesc>The first part is distributed within FacetNFT, called FacetMagicBox, with a total supply of 3138. The public minting price was 0.02 ETH and it has ended.</RightDesc>
                    <RightDesc>The second part is distributed within Base, called BaseMagicBox, with a total supply of 6862. The minting price for the first 1000 BaseMagicBoxes was 0.017 ETH, while the minting price for the rest is 0.02 ETH.</RightDesc>
                    <InfoBox>
                        <TimeCountDown>
                            <CountDownItem>{cutDownData.d}D</CountDownItem>
                            <CountDownItem>{cutDownData.h}H</CountDownItem>
                            <CountDownItem>{cutDownData.m}M</CountDownItem>
                            <CountDownItem>{cutDownData.s}S</CountDownItem>
                        </TimeCountDown>
                        <InfoRow>Mint start: Tue, 17 Apr 2024 13:00:00 GMT</InfoRow>
                        <InfoRow>Max: {nftData.maxBoxs}</InfoRow>
                        <InfoRow>Total Minted: {nftData.totalSupply}</InfoRow>
                    </InfoBox>
                </RightWrap>
            </Box>

            <TipDialog onClose={() => setOpenTipDialog(false)} open={openTipDialog}>
                <TipOuter>
                    <TipSuccessIcon></TipSuccessIcon>
                    <TipLint onClick={() => window.location.href = `https://basescan.org/tx/${tipDialogText}`}>The transaction was successfully sent →</TipLint>
                </TipOuter>
            </TipDialog>
        </Wrap>
    )
}