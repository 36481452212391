import styled from 'styled-components';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useNavigate } from 'react-router-dom';

const Wrap = styled.div.attrs({
    className: 'relative w-full'
})``;

const NavWrap = styled.div.attrs({
    className: 'relative overflow-hidden'
})`
    height: calc(100vh - 72px);
`;

const BgIcon = styled.img.attrs({
    className: 'absolute right-[64px] top-0 w-[1100px] max-[1536px]:w-[800px] max-md:hidden'
})``;

const MainDescWrap = styled.div.attrs({
    className: 'max-w-[1664px] mx-auto relative h-full'
})``;

const MainDescInner = styled.div.attrs({
    className: 'absolute bottom-[64px] left-[64px] max-md:left-0 max-md:px-[12px] w-[40%] max-md:w-full'
})``;

const MainTitle = styled.div.attrs({
    className: 'text-[64px] max-md:text-[32px] font-semibold',
    'data-aos': "fade-right"
})``;

const MainDesc = styled.div.attrs({
    className: 'mt-[12px] text-[24px] max-md:text-[18px] font-extralight leading-tight',
    'data-aos': "fade-right",
    'data-aos-delay': 500,
    'data-aos-duration': 300
})``;

const ModuleOneWrap = styled.div.attrs({
    className: 'flex max-md:block justify-between items-stretch'
})``;

const ModuleOneLeft = styled.div.attrs({
    className: 'h-[380px] max-md:h-auto w-[48%] max-md:w-full p-[32px] pt-[100px] max-md:pt-[24px]',
    'data-aos': "fade-right",
})`
    border-radius: 24px;
    background-image: url('/imgs/image 7.png');
    background-size: 100% 100%;
`;

const ModuleOneLeftTitle = styled.div.attrs({
    className: 'text-[32px] max-md:text-[24px]'
})`
`;

const ModuleOneLeftDesc = styled.div.attrs({
    className: 'text-[20px] mt-[24px] max-md:text-[16px]'
})`
    color: rgba(255,255,255,0.7);
`;

const ModuleOneRight = styled.div.attrs({
    className: 'w-[48%] max-md:w-full flex-col flex justify-between',
    'data-aos': "fade-left",
})``;

const ModuleOneRightBox = styled.div.attrs({
    className: 'p-[24px] h-[170px] max-md:h-[200px] cursor-pointer max-md:mt-[20px]'
})`
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 24px;
    background-color: rgba(255,255,255,0.06);
    transition: opacity 250ms ease;
    &:hover {
        opacity: 0.8;
    }
`;

const ModuleOneRightTitle = styled.div.attrs({
    className: 'text-[24px] max-md:text-[20px]'
})``;

const ModuleOneRightDesc = styled.div.attrs({
    className: 'mt-[12px] text-[18px] max-md:text-[16px]'
})`
    color: rgba(255,255,255,0.8);
`;

const ModuleTwoWrap = styled.div.attrs({
    className: 'mt-[200px] flex max-md:block justify-between items-stretch max-md:mt-[64px]'
})``;


const ModuleTwoLeft = styled.img.attrs({
    className: 'w-[48%] max-md:w-full max-md:mt-[24px]',
    'data-aos': "fade-left",
})`
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 24px;
`;

const ModuleTwoRight = styled.div.attrs({
    className: 'w-full'
})``;

const ModuleTwoRightWrap = styled.div.attrs({
    className: 'w-[48%] max-md:w-full flex max-md:block flex-col justify-center',
    'data-aos': "fade-right",
})``;

const ModuleTwoRightTitle = styled.div.attrs({
    className: 'text-[32px] max-md:text-[24px] mb-[24px]'
})``;

const ModuleTwoRightDesc = styled.div.attrs({
    className: 'text-[20px] mt-[12px] max-md:text-[16px]'
})`
    color: rgba(255,255,255,0.6);
`;

const ModuleThreeWrap = styled.div.attrs({
    className: 'mt-[200px] max-md:mt-[64px]',
    'data-aos': "fade-up",
})`
`;

const ModuleThreeInner = styled.div.attrs({
    className: 'p-[24px] text-[20px] max-md:text-[14px] flex items-center'
})`
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 12px;
    background-color: rgba(255,255,255,0.06);
    color: #FD7E14;
`;

const ModuleThreeText = styled.div.attrs({
    className: ''
})`
    
`;

const Content = styled.div.attrs({
    className: 'max-w-[1664px] max-md:w-full max-md:overflow-hidden mx-auto px-[64px] max-md:px-[12px] mt-[200px] max-md:mt-[64px]'
})``;

const BridgeGuideBtn = styled.div.attrs({
    className: 'h-[36px] inline-flex items-center rounded-[12px] px-[24px] mt-[24px] cursor-pointer'
})`
    background: linear-gradient(225deg, #7d41ea 46.35%, #FD7E14);
    transition: transform 250ms ease;
    &:hover {
        transform: scale(1.06);
    }
`

const BridgeGuideText = styled.div.attrs({
    className: 'text-[20px] cursor-pointer mt-[32px]'
})`
    transition: color 250ms ease;
    &:hover {
        color: rgba(255,255,255,0.8);
    }
`

const LinkIcon = styled(CallMadeIcon).attrs({
    className: 'text-[20px]'
})``;

const ContactWrap = styled.div.attrs({
    className: 'flex items-center mt-[24px]'
})``;

const Footer = styled.div.attrs({
    className: 'flex items-center mt-[64px] py-[48px] px-[64px] max-md:px-[12px]'
})``

const Logo = styled.img.attrs({
    className: 'w-[42px] max-md:w-[32px] ml-auto'
})``

const FooterContact = styled.div.attrs({
    className: 'mr-auto text-[12px] cursor-pointer'
})`
    color: rgba(255,255,255,0.6);
    &:hover {
        color: rgba(255,255,255,0.8);
    }
`

const BgBox = styled.div.attrs({
    className: 'absolute left-0 top-[-72px] max-md:top-[-56px]'
})`
    background-image: url('/imgs/nav-bg.png');
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
`

export default function Home() {
    const navigate = useNavigate();

    return (
        <Wrap>
            <BgBox></BgBox>
            <NavWrap>
                <MainDescWrap>
                    <MainDescInner>
                        <MainTitle>
                            FacetRGB
                        </MainTitle>
                        <MainDesc>FacetRGB is a new extension layer related to the Facet protocol, aimed at facilitating the flow of assets from the Facet ecosystem to other networks and vice versa.</MainDesc>
                        <ContactWrap>
                            <svg data-aos="fade-up" data-aos-delay="700" data-aos-duration="300" onClick={() => window.location.href = 'https://twitter.com/geekey_eth'} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="h-[20px] w-[20px] mr-[12px] cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                            {/* <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" class="h-[24px] w-[24px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg> */}
                        </ContactWrap>
                        
                    </MainDescInner>
                </MainDescWrap>
            </NavWrap>

            <Content>
                <ModuleOneWrap>
                    <ModuleOneLeft>
                        <ModuleOneLeftTitle>Facet to Base.</ModuleOneLeftTitle>
                        <ModuleOneLeftDesc>FacetRGB is a bridge between Facet and Base. Assets in the Facet ecosystem can enter the Base through FacetRGB and can be reversely returned.FacetMagicBox will be the first experimental asset.</ModuleOneLeftDesc>
                        <BridgeGuideBtn onClick={() => navigate('/bridge')}>Launch <LinkIcon></LinkIcon></BridgeGuideBtn>
                    </ModuleOneLeft>
                    <ModuleOneRight>
                        <ModuleOneRightBox onClick={() => window.location.href = 'https://api-docs.facet.org/reference'}>
                            <ModuleOneRightTitle>Facet →</ModuleOneRightTitle>
                            <ModuleOneRightDesc>Facet is an extension layer built on the Ethereum mainnet. It serves as a decentralized off-chain computing platform, with data stored on the Ethereum mainnet.</ModuleOneRightDesc>
                        </ModuleOneRightBox>
                        <ModuleOneRightBox onClick={() => window.location.href = 'https://www.base.org/'}>
                            <ModuleOneRightTitle>Base →</ModuleOneRightTitle>
                            <ModuleOneRightDesc>Base is a secure, low-cost, builder-friendly Ethereum L2 built to bring the next billion users onchain.</ModuleOneRightDesc>
                        </ModuleOneRightBox>
                    </ModuleOneRight>
                </ModuleOneWrap>

                <ModuleTwoWrap>
                    <ModuleTwoRightWrap>
                        <ModuleTwoRight>
                            <ModuleTwoRightTitle>Bitcoin to Facet.</ModuleTwoRightTitle>
                            <ModuleTwoRightDesc>FacetRGB is a bridge between Bitcoin and Facet. Assets in the Bitcoin ecosystem can enter the Facet through FacetRGB and can be reversely returned.</ModuleTwoRightDesc>
                            <ModuleTwoRightDesc>This is a new direction of exploration, and the Facet becomes the Bitcoin Layer2.</ModuleTwoRightDesc>
                            <BridgeGuideText onClick={() => navigate('/bridge')}>Launch →</BridgeGuideText>
                        </ModuleTwoRight>
                    </ModuleTwoRightWrap>

                    <ModuleTwoLeft src='/imgs/vctrly-business-illustrations-220-work-on-computer.png'></ModuleTwoLeft>
                </ModuleTwoWrap>

                <ModuleThreeWrap>
                    <ModuleThreeInner>
                        <ModuleThreeText>Facet is a decentralized, low-cost computina piatom,FacetRGB expands the possibilities of Facet.</ModuleThreeText>
                        <Logo src='/imgs/logo.png'></Logo>
                    </ModuleThreeInner>
                </ModuleThreeWrap>
            </Content>

            <Footer>
                <FooterContact onClick={() => window.location.href = 'https://twitter.com/geekey_eth'}>Media inquires for FacetRGB - Contact @geekey_eth</FooterContact>
                <svg onClick={() => window.location.href = 'https://twitter.com/geekey_eth'} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="h-[20px] w-[20px] mr-[12px] cursor-pointer max-md:hidden" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
            </Footer>
        </Wrap>
    )
}