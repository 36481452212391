import styled from 'styled-components';
import { useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ExchangeBox = styled.div.attrs({
    className: 'mt-[24px] pb-[50px]'
})`
`;

const ExchangeItem = styled.div.attrs({
    className: 'p-[24px] w-[760px] max-md:w-full mx-auto rounded-[24px] flex max-md:block'
})`
    background-color: rgba(255,255,255,0.06);
`;

const ExchangeLeft = styled.div.attrs({
    className: 'w-[320px] max-md:w-full mr-[24px]'
})`
`;

const ExchangeRight = styled.div.attrs({
    className: 'flex-1 rounded-[12px] p-[12px] max-md:mt-[12px]'
})`
    border: 1px solid rgba(255,255,255,0.12);
`;

const ChainBox = styled.div.attrs({
    className: 'flex items-center rounded-[12px] h-[50px] px-[24px]'
})`
    background-color: rgba(255,255,255,0.06);
`;

const ChainIcon = styled.img.attrs({
    className: 'w-[25px] h-[25px] mr-[6px] rounded-[25px]'
})`
`;

const ChainName = styled.div.attrs({
    className: 'text-[14px]'
})`
`;

const TokenBox = styled.div.attrs({
    className: 'mt-[12px] flex items-center rounded-[12px] h-[50px] px-[24px]'
})`
    border: 1px solid rgba(255,255,255,0.36);
`;

const TokenIcon = styled.img.attrs({
    className: 'w-[25px] h-[25px] mr-[6px] rounded-[25px]'
})`
`;

const TokenName = styled.div.attrs({
    className: 'text-[14px]'
})`
`;

const ArrowIcon = styled(ArrowDownwardIcon).attrs({
    className: '!block mx-auto !w-[40px] !h-[40px] p-[12px] rounded-[40px] my-[12px] cursor-pointer'
})`
    border: 1px solid rgba(255,255,255,0.12);
    background-color: rgba(255,255,255,0.06);
    transition: all 250ms ease;
    &:hover {
        background-color: rgba(255,255,255,0.12);
    }
`

const TokenAmountTip = styled.div.attrs({
    className: 'text-right text-[12px]'
})`
    color: rgba(255,255,255,0.6);
`;

const TokenInput = styled.input.attrs({
    className: 'w-full text-right text-[30px]'
})`
`;

const TokenBalance = styled.div.attrs({
    className: 'text-right text-[12px]'
})`
    color: rgba(255,255,255,0.6);
`;

const SubmitBtn = styled.div.attrs({
    className: 'w-[760px] max-md:w-full text-[20px] font-semibold text-center mt-[24px] mx-auto h-[50px] leading-[50px] bg-[#FD7E14] rounded-[8px]'
})`
    opacity: 0.6;
`;

const bridgeToken = {
    tokenName: 'FacetRGB',
    tokenIcon: '/imgs/chains/base-logo-79dee8a2.svg'
}

const from = {
    chainIcon: '/imgs/chains/bitcoin.svg',
    chainName: 'Bitcoin',
    ...bridgeToken
}

const to = {
    chainIcon: '/imgs/chains/facet_logo.jpg',
    chainName: 'Facet',
    ...bridgeToken
}

export default function ExchangeFacet() {
    const [isReverse, setIsReverse] = useState(false);
    const [bridgeFlow, setBridgeFlow] = useState({from, to});

    function onExchangeFlow() {
        if(isReverse) {
            setBridgeFlow({from, to});
        } else {
            setBridgeFlow({from: to, to: from});
        }

        setIsReverse(!isReverse);
    }

    return (
        <ExchangeBox>
            <ExchangeItem>
                <ExchangeLeft>
                    <ChainBox>
                        <ChainIcon src={bridgeFlow.from.chainIcon}></ChainIcon>
                        <ChainName>{bridgeFlow.from.chainName}</ChainName>
                    </ChainBox>
                    <TokenBox>
                        <TokenIcon src='/imgs/tokens/runestone.avif'></TokenIcon>
                        <TokenName>Runestone</TokenName>
                    </TokenBox>
                </ExchangeLeft>
                <ExchangeRight>
                    <TokenAmountTip>You send</TokenAmountTip>
                    <TokenInput placeholder='0'></TokenInput>
                    <TokenBalance>Balance 0</TokenBalance>
                </ExchangeRight>
            </ExchangeItem>

            <ArrowIcon onClick={onExchangeFlow}></ArrowIcon>

            <ExchangeItem>
                <ExchangeLeft>
                    <ChainBox>
                        <ChainIcon src={bridgeFlow.to.chainIcon}></ChainIcon>
                        <ChainName>{bridgeFlow.to.chainName}</ChainName>
                    </ChainBox>
                    <TokenBox>
                        <TokenIcon src='/imgs/tokens/runestone.avif'></TokenIcon>
                        <TokenName>Runestone</TokenName>
                    </TokenBox>
                </ExchangeLeft>
                <ExchangeRight>
                    <TokenAmountTip>You receive</TokenAmountTip>
                    <TokenInput readOnly placeholder='0'></TokenInput>
                    <TokenBalance>Balance 0</TokenBalance>
                </ExchangeRight>
            </ExchangeItem>

            <SubmitBtn>Soon</SubmitBtn>
        </ExchangeBox>
    )
}