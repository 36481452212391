import styled from 'styled-components';

const Wrap = styled.div.attrs({
    className: 'text-center pt-[120px]'
})``;

const TipText = styled.div.attrs({
    className: 'text-center text-[18px]'
})`
    color: rgba(255,255,255,0.8);
`;

const TipImg = styled.img.attrs({
    className: 'block mx-auto w-[320px]'
})``;

export default function Mining() {
    return (
        <Wrap>
            <TipImg src='/imgs/Empty State Illustrations_Dark Mode_No Saving.png'></TipImg>
            <TipText>FacetBaseBox Mine coming soon ~</TipText>
        </Wrap>
    )
}