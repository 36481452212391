import { RouterProvider } from "react-router-dom";
import router from './routers';
import Web3Provider from './components/web3-provider';

function App() {
  return (
    <Web3Provider>
      <RouterProvider router={router} />
    </Web3Provider>
  );
}

export default App;
