import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from 'styled-components';

const ConnectedButton = styled.div.attrs({
    className: 'h-[46px] flex items-center cursor-pointer'
})``

const Avatar = styled.img.attrs({
    className: 'w-[32px] h-[32px] rounded-[32px] mr-[12px]'
})``

const AddressText = styled.div.attrs({
    className: ''
})``

const ConnectBtn = styled.div.attrs({
    className: 'px-[24px] rounded-[12px] h-[46px] leading-[46px] cursor-pointer'
})`
    border: 1px solid #FD7E14;
    color: #FD7E14;
    transition: opacity 250ms ease;
    &:hover {
        opacity: 0.8;
    }
`;

const ChainButton = styled.div.attrs({
    className: 'px-[24px] rounded-[12px] h-[46px] leading-[46px] cursor-pointer'
})`
    border: 1px solid #dc2626;
    color: #dc2626;
    transition: opacity 250ms ease;
    &:hover {
        opacity: 0.8;
    }
`;

export default function CustomConnectButton() {
    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openConnectModal,
                openChainModal,
                authenticationStatus,
                mounted,
            }) => {
                const ready = mounted && authenticationStatus !== 'loading';
                const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

                if (ready) {
                    if (!connected) {
                        return (
                            <ConnectBtn onClick={openConnectModal}>Connect Wallet</ConnectBtn>
                        )
                    }

                    if(chain.unsupported) {
                        return (
                            <ChainButton onClick={openChainModal}>Wrong network</ChainButton>
                        )
                    }

                    if (connected) {
                        return (
                            <ConnectedButton onClick={() => openAccountModal()}>
                                <Avatar src={account.ensAvatar || '/imgs/head.webp'}></Avatar>
                                <AddressText>{account.displayName}</AddressText>
                            </ConnectedButton>
                        )
                    }
                }

                return null;
            }}
        </ConnectButton.Custom>
    )
}