import styled from 'styled-components';
import BlurOnIcon from '@mui/icons-material/BlurOn';

const Wrap = styled.div.attrs({
    className: 'pt-[120px] max-md:pt-[56px] px-[64px] max-md:px-[12px]'
})``;

const Inner = styled.div.attrs({
    className: 'max-w-[1664px] mx-auto px-[64px] max-md:px-[12px] flex justify-between'
})``;

const BoxWrap = styled.div.attrs({
    className: 'p-[24px] w-[48%] max-md:w-[48%] mb-[80px] max-md:mb-[20px] relative cursor-pointer'
})`
    border: 1px solid rgba(255,255,255,0.12);
    background-color: rgba(255,255,255,0.06);
    border-radius: 24px;
    transition: background 250ms ease;
    &:hover {
        background-color: rgba(255,255,255,0.12);
    }
`

const BoxTitle = styled.div.attrs({
    className: 'text-[32px] max-md:text-[24px]'
})``

const BoxDesc = styled.div.attrs({
    className: 'mt-[12px]'
})`
    color: rgba(255,255,255,0.8);
`

const DecIcon = styled(BlurOnIcon).attrs({
    className: 'absolute right-0 top-0'
})`
    font-size: 32px;
`

export default function Ecology() {

    function onRouter(url) {
        window.location.href = url;
    }

    return (
        <Wrap>
            <Inner>
                <BoxWrap onClick={() => onRouter('https://facetswap.com/')}>
                    <BoxTitle>Facet Swap</BoxTitle>
                    <BoxDesc>Dex built on Facet</BoxDesc>
                    <DecIcon></DecIcon>
                </BoxWrap>
                <BoxWrap onClick={() => onRouter('https://facetnft.com/')}>
                    <BoxTitle>FacetNFT</BoxTitle>
                    <BoxDesc>NFT in the Facet ecosystem</BoxDesc>
                    <DecIcon></DecIcon>
                </BoxWrap>
            </Inner>
            <Inner>
                <BoxWrap onClick={() => onRouter('https://facetcards.com/')}>
                    <BoxTitle>Facet Cards</BoxTitle>
                    <BoxDesc>Name services in the Facet ecosystem</BoxDesc>
                    <DecIcon></DecIcon>
                </BoxWrap>
                <BoxWrap onClick={() => onRouter('https://facetscan.com/')}>
                    <BoxTitle>Facet Scan</BoxTitle>
                    <BoxDesc>Blockchain browser in the Facet ecosystem</BoxDesc>
                    <DecIcon></DecIcon>
                </BoxWrap>
            </Inner>
        </Wrap>
    )
}